import { 
  title, 
  whiteColor,
  grayColor,
  mlAuto,
  mrAuto,
} from 'assets/jss/styles.js'

const mainPageStyle = {
  mlAuto,
  mrAuto,  
  container: {
    marginTop: "80px",
    marginBottom: "80px"
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: whiteColor,
    textDecoration: 'none',
  },
  titleSection: {
    color: grayColor[1],
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginBottom: "15px !important"
  },
  subtitle: {
  	color: whiteColor,
  	fontSize: "1.2rem"
  },
  middle: {
    alignSelf: "center"
  },
  info: {
    maxWidth: "360px",
    margin: "0 auto",
  },
  imgContainer: {
    padding: "25px 0",
    "& .gatsby-image-wrapper": {
      maxHeight: "400px",
      height: "260px",
      minHeight: "230px",
      marginBottom: "15px"
    },
    "& .vertical .gatsby-image-wrapper": {
      minHeight: "400px",
    },
  },
  titleBgFilter: {
    backgroundColor: "rgba(0,0,0,.3)",
    padding: "15px",
    "& h1": {
      marginTop: "10px"
    }
  }
}

export default mainPageStyle
